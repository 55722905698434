import React from 'react'
import { Link } from 'gatsby'
import BetterIcon from '@/components/utils/BetterIcon'
import pauseIcon from '@/icons/ui-pause.svg'
import playIcon from '@/icons/ui-play.svg'

const Tag = ({ to, children, onClick, className }) =>
	to ? (
		<Link to={to} className={`${className}`} onClick={onClick}>
			{children}
		</Link>
	) : (
		<button
			aria-label="Play/Pause"
			type="button"
			className={`${className}`}
			onClick={onClick}
		>
			{children}
		</button>
	)

export default ({
	onClick,
	playing,
	to,
	sizeClass = 'h-80',
	iconSizeClass = 'h-20 w-20'
}) => (
	<Tag
		to={to}
		onClick={onClick}
		className="h-65 flex flex-no-wrap items-center px-1-5 rounded-play justify-center border-3 rounded border-orange focus:bg-white focus:text-black trans outline-none"
	>
		<span className="whitespace-no-wrap whitespace-no-wrap text-lg-fluid ">
			Listen now
		</span>
		<span className="visuallyhidden">{!playing ? 'Play' : 'Pause'}</span>
	</Tag>
)
