import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'gatsby'
import Image from '@/components/utils/Image'

class SeriesListing extends Component {
	/**
	 * @method onClick
	 * update the current track, and togglePlay if it makes sense
	 * the function is called within an inline click handle, which
	 * handles the event object (i.e. preventDefault)
	 *
	 * @param {number} index
	 *
	 */
	onClick = track => {
		// reset the current audio state
		const { setTrack } = this.props

		setTrack(track)
	}

	render() {
		const { episodes, current, title } = this.props

		return (
			<div>
				<h2 className="text-white font-bold text-h2-fluid lowercase mb-2">
					{title}
				</h2>
				<div className="pb-3 border-t-2 border-tundora">
					<div className="text-xs-fluid">
						{episodes.map(item => (
							<div
								key={item.id}
								className="border-b-2 border-tundora pt-0-5 pb-1 lg:pt-1 sm:py-0-75"
							>
								<div className="w-full sm:w-auto flex items-center flex-no-wrap">
									<div className="mr-0-5 lg:mr-1 sm:mb-0 w-8/24 lg:w-4/24">
										<Image fluid={item.image} />
									</div>

									<h2 className="pr-0-75 leading-none w-20/24">
										{item.id === current ? (
											<span className="text-lg-fluid font-bold lowercase text-orange focus:text-orange outline-none trans trans-color">
												{item.title}
											</span>
										) : (
											<Link
												to={item.uri}
												className="text-lg-fluid font-bold lowercase hover:text-orange focus:text-orange outline-none trans trans-color"
											>
												{item.title}
											</Link>
										)}
									</h2>

									<div className="meta inline-flex ml-auto items-center justify-end lg:flex-1 whitespace-no-wrap">
										<span className="w-px bg-grey-brd h-20 mx-0-5 block" />
										<span className="text-grey-light text-right">
											{item.postDate}
										</span>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		)
	}
}

const mapState = state => ({
	...state.player
})

const mapDispatch = ({
	player: {
		setAudioBarVisibility,
		setPageType,
		togglePlay,
		reset,
		setCurrentPageId,
		stop,
		setTrack
	}
}) => ({
	setAudioBarVisibility,
	setPageType,
	togglePlay,
	reset,
	setCurrentPageId,
	stop,
	setTrack
})

export default connect(
	mapState,
	mapDispatch
)(SeriesListing)
