/* disable-stylelint */

import React, { Component } from 'react'
import { css } from 'react-emotion'
import { fluidtype, boldFont } from '@/styles/helpers'

const wysiwyg = css`
	h2 {
		font-size: ${fluidtype('h2')};
		font-family: ${boldFont};
	}

	h3 {
		font-size: ${fluidtype('h3')};
		font-family: ${boldFont};
	}

	h4 {
		font-size: ${fluidtype('h4')};
		font-family: ${boldFont};
	}

	h5 {
		font-size: ${fluidtype('h5')};
		font-family: ${boldFont};
	}
`

export default class PostWysiwyg extends Component {
	render() {
		const { children } = this.props

		return (
			<article
				className={`${wysiwyg} c-wysiwyg text-body-fluid c-wysiwyg-spacing text-grey-light`}
			>
				{children}
			</article>
		)
	}
}
