import React, { Component } from 'react'
import rightChevron from '@/icons/ui-right-chevron.svg'
import Icon from '@/components/utils/Icon'
import Image from '@/components/utils/Image'
import { css } from 'react-emotion'

const overlayStyle = css`
	&::before {
		opacity: 0;
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.7);
		transition: opacity 300ms;
	}

	&:hover::before {
		opacity: 1;
	}

	& .stripe {
		opacity: 0;
	}

	&:hover .stripe {
		opacity: 1;
	}
`

export default class OrgThumb extends Component {
	render() {
		const { title, directoryImage, telephone, address, website } = this.props

		return (
			<div className="mb-2 text-xxs-fluid">
				<a
					href={website}
					tabIndex="-1"
					rel="noopener noreferrer"
					className={`${overlayStyle} block relative mb-1 trans trans-opacity trans-normal`}
				>
					<Image fluid={{ ...directoryImage[0].optimisedDirectory, title }} />
					<div
						href={website}
						className="absolute stripe flex items-center pin-x pin-b bg-teal p-0-5 trans trans-opacity"
					>
						<span className="mr-0-5">visit website</span>
						<span className="text-white">
							<Icon icon={rightChevron} />
						</span>
					</div>
				</a>
				<h3 className="block text-lg-fluid font-bold mb-1">
					<a
						href={website}
						target="_blank"
						rel="noopener noreferrer"
						className="hover:text-orange trans"
					>
						{title}
					</a>
				</h3>
				<p className="leading-tight mb-1 text-grey-light">{address}</p>
				{telephone && (
					<p className="text-grey-light mb-1">
						tel{' '}
						<a
							href={`tel:${telephone}`}
							className="text-white font-bold hover:text-orange focus:text-orange outline-none trans trans-color"
						>
							{telephone}
						</a>
					</p>
				)}
				<a
					className="font-bold hover:text-orange focus:text-orange outline-none trans trans-color inline-flex items-center"
					href={website}
					target="_blank"
					rel="noopener noreferrer"
				>
					<span className="mr-0-5">website</span>
					<span className="text-teal">
						<Icon icon={rightChevron} />
					</span>
				</a>
			</div>
		)
	}
}
