import React, { Component, Fragment } from 'react'
import * as R from 'ramda'
import { graphql } from 'gatsby'
import PostWysiwyg from '@/components/PostWysiwyg/PostWysiwyg'
import Hero from '@/components/Hero/Hero'
import Shows from '@/shared/Shows'
import {
	prepRelatedContent,
	prepDirectoryItem,
	prepSeriesContent,
	getSeoImage
} from '@/utils'
import RelatedContent from '@/shared/RelatedContent'
import SEO from '@/components/SEO/SEO'
import SeriesListing from '@/components/SeriesListing/SeriesListing'
import Social from '@/components/Social/Social'
import Tags from '@/components/Tags/Tags'
/* eslint-disable */
import {
	relatedVideo,
	relatedBlog,
	relatedAudio,
	optimisedImage
} from '@/queries'
/* eslint-enable */

export default class AudioPost extends Component {
	onViewChange = inView => {
		const { setAudioBarVisibility } = this.props

		setAudioBarVisibility(!inView)
	}

	render() {
		const {
			data: {
				craft: {
					entry,
					related: tmpRelated,
					directory: tmpDirectory,
					series: { edges: tmpSeries }
				}
			},
			pageContext,
			location
		} = this.props

		const { series, episodes } = prepSeriesContent(tmpSeries)

		const related = R.compose(
			R.take(8),
			prepRelatedContent
		)(tmpRelated)

		const directory = prepDirectoryItem(tmpDirectory)

		const {
			socialMetaTitle,
			socialMetaDescription,
			seoTitle,
			seoDescription,
			subtitle
		} = entry
		const seoImage = getSeoImage(entry)

		return (
			<Fragment>
				<SEO
					title={seoTitle || entry.title}
					description={seoDescription || subtitle}
					socialTitle={socialMetaTitle || entry.title}
					socialDesc={socialMetaDescription || subtitle}
					image={seoImage}
					url={location.href}
				/>
				<Shows
					pageContext={pageContext}
					entry={entry}
					render={({
						title,
						subtitle,
						image,
						tags,
						body,
						episode,
						date,
						banner,
						track
					}) => (
						<Fragment>
							<div className="mb-2 lg:mb-4 relative">
								<Hero
									track={track}
									title={title}
									subtitle={subtitle}
									image={banner || image}
									tags={tags}
									entry={entry}
									url={location.href}
								/>
							</div>
							<div className="wrapper mb-4">
								<div className="mx-auto max-w-800">
									<h1 className="text-xlh-fluid mb-2 font-bold lowercase">
										{title}
									</h1>
									{(episode || date) && (
										<p className="mb-0-5 text-grey-light text-md-fluid">
											{episode && (
												<span className="mr-1 pr-1 border-r border-grey-light">
													episode {episode}
												</span>
											)}
											{date && <span>{date}</span>}
										</p>
									)}
									<PostWysiwyg>
										<div dangerouslySetInnerHTML={{ __html: body }} />
									</PostWysiwyg>
								</div>
							</div>
							<div className="wrapper">
								<div className="max-w-800 mx-auto mb-4">
									<div className="sm:flex sm:justify-between">
										<div className="mb-1 sm:mb-0">
											<Tags tags={tags} />
										</div>
										<Social title={title} url={location.href} />
									</div>
								</div>

								{episodes ? (
									<div className="max-w-800 mx-auto mb-4">
										<SeriesListing
											episodes={episodes}
											current={entry.id}
											track={entry}
											title={series.title}
										/>
									</div>
								) : null}
							</div>
							<RelatedContent related={related} directory={directory} />
						</Fragment>
					)}
				/>
			</Fragment>
		)
	}
}

export const pageQuery = graphql`
	query AudioPostByUri($uri: String!, $searchTags: String!, $id: [Int]!) {
		craft {
			entry: entry(uri: $uri) {
				...relatedAudio
				... on Craft_Audio {
					bannerImage {
						title
						... on Craft_ImagesVolume {
							optimisedBanner {
								... on Craft_OptimizedImagesData {
									src
									srcset
									srcWebp
									srcsetWebp
									maxSrcsetWidth
									placeholderWidth
									placeholderHeight
									colorPalette
								}
							}
						}
					}

					seo {
						title
						description

						social {
							twitter {
								title
								image {
									url
								}
								description
							}
							facebook {
								title
								image {
									url
								}
								description
							}
						}
					}
				}
			}

			series: entriesConnection(id: $id) {
				edges {
					relatedCategories {
						categories {
							... on Craft_EpisodesCategory {
								title
								episodes {
									... on Craft_Audio {
										title
										uri
										id
										postDate
										duration
										mediaSource
										image {
											... on Craft_ImagesVolume {
												...optimisedImage
											}
										}
									}
									... on Craft_Video {
										title
										uri
										id
										postDate
										duration
										mediaSource
										image {
											... on Craft_ImagesVolume {
												...optimisedImage
											}
										}
									}
								}
							}
						}
					}
				}
			}

			related: tagsConnection(search: $searchTags) {
				edges {
					relatedEntries(
						section: [blog, audio, video]
						limit: 3
						idNot: $id
						order: "postDate desc"
					) {
						entries {
							...relatedBlog
							...relatedAudio
							...relatedVideo
						}
					}
				}
			}

			directory: tagsConnection(search: $searchTags) {
				edges {
					relatedEntries(
						section: [directory]
						order: "postDate desc"
						limit: 8
					) {
						entries {
							... on Craft_Directory {
								title
								id
								address
								telephone
								website
								location
								tags {
									title
									id
								}
								directoryImage {
									... on Craft_DirectoryVolume {
										optimisedDirectory {
											... on Craft_OptimizedImagesData {
												src
												srcset
												srcWebp
												srcsetWebp
												maxSrcsetWidth
												placeholderWidth
												placeholderHeight
												colorPalette
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`
