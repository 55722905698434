import React, { Component } from 'react'
import facebookIcon from '@/icons/ui-facebook.svg'
import twitterIcon from '@/icons/ui-twitter.svg'
import BetterIcon from '@/components/utils/BetterIcon'

import { FacebookShareButton, TwitterShareButton } from 'react-share'

export default class Social extends Component {
	shouldComponentUpdate = () => false

	render() {
		const { url, title, color = 'text-white' } = this.props

		return (
			<div className="text-xs-fluid flex">
				<span className="mr-0-75 md:mr-1">Share</span>
				<ul className={`list-reset ${color} flex items-center`}>
					<li className="mr-0-75">
						<FacebookShareButton
							url={url}
							quote={title}
							className="hover:text-orange focus:text-orange outline-none"
						>
							<BetterIcon icon={facebookIcon} className="w-20" />
							<span className="visuallyhidden">share on facebook</span>
						</FacebookShareButton>
					</li>
					<li className="mr-0-75">
						<TwitterShareButton
							url={url}
							title={title}
							className="hover:text-orange focus:text-orange outline-none"
						>
							<BetterIcon icon={twitterIcon} className="w-20" />
							<span className="visuallyhidden">share on twitter</span>
						</TwitterShareButton>
					</li>
				</ul>
			</div>
		)
	}
}
