import React, { Component, Fragment } from 'react'

export default class SectionHeading extends Component {
	render() {
		const { title, subtitle } = this.props

		return (
			<Fragment>
				<div className="relative mb-1 md:mb-0-5">
					<h2 className="text-h4-fluid font-bold lowercase relative">
						<span className="absolute h-5 w-screen pin-t mt-1-25 pin-r-100 bg-aluminium mr-2" />
						{title}
					</h2>
				</div>
				{subtitle && (
					<p className="text-sm-fluid hidden md:block">{subtitle}</p>
				)}
			</Fragment>
		)
	}
}
