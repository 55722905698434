import React, { Component } from 'react'
import BetterIcon from '@/components/utils/BetterIcon'

export default class Error extends Component {
	render() {
		const { icon, title, message, children } = this.props

		return (
			<div className="flex flex-col items-center justify-center z-20 relative py-2">
				<div className="mb-2">
					<BetterIcon icon={icon} className="w-80" />
				</div>
				<h2 className="text-h3-fluid mb-1 text-white">{title}</h2>
				<p className="text-body-fluid text-grey-light">{message}</p>
				<div>{children}</div>
			</div>
		)
	}
}
