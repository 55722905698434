import React, { Component, Fragment } from 'react'

import { connect } from 'react-redux'
import BigPlayPause from '@/components/utils/BigPlayPause'
import BigAudioPlayButton from '@/components/utils/BigAudioPlayButton'
// import { convertTimeStringToSeconds } from '@/utils'
import Loader from '@/components/utils/Loader'

class AudioPlayer extends Component {
	onClick = () => {
		const {
			track,
			currentId,
			togglePlay,
			entry,
			setTrack,
			setAudioBarVisibility,
			showUi
		} = this.props

		const { id: playingId } = track
		// should the audio player be in the "playing" state
		// if the current playing audio matches the current page
		// we should be in the playing state
		const shouldBePlaying = playingId === currentId

		if (showUi) {
			togglePlay()
		} else {
			setAudioBarVisibility(true)
		}

		if (!shouldBePlaying) {
			setTrack(entry)
		}
	}

	render() {
		const { playing, track, currentId, isReady, showUi, played } = this.props

		// if there ain't no track... bail
		if (!track) return null

		const { id: playingId } = track

		// are we currently playing the track that matches the page
		const playingCurrentTrack = playingId === currentId
		// should we be in the playing state
		// POSSIBLE BUT... CHANGING
		const shouldBePlaying = playingCurrentTrack && playing

		return (
			<Fragment>
				<div className="mx-auto mb-2-25  md:order-1">
					<div className="text-orange">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="88"
							height="62"
							viewBox="0 0 88 62"
							className="mx-auto w-70 block text-orange fill-current"
						>
							<g
								transform="rotate(-180 30 31)"
								className={shouldBePlaying ? 'is-playing' : ''}
							>
								<rect
									style={{ animationDelay: '0ms' }}
									width="5"
									height="20.308"
									x="-27.5"
								/>
								<rect
									style={{ animationDelay: '50ms' }}
									width="5"
									height="33"
									x="-13.75"
								/>
								<rect
									style={{ animationDelay: '100ms' }}
									width="5"
									height="32.898"
								/>
								<rect
									style={{ animationDelay: '150ms' }}
									width="5"
									height="62"
									x="13.75"
								/>
								<rect
									style={{ animationDelay: '200ms' }}
									width="5"
									height="32.898"
									x="27.5"
								/>
								<rect
									style={{ animationDelay: '250ms' }}
									width="5"
									height="20.245"
									x="41.25"
								/>
								<rect
									style={{ animationDelay: '300ms' }}
									width="5"
									height="32.898"
									x="55"
								/>
							</g>
						</svg>
					</div>
				</div>
				<div className="flex justify-center mb-2 lg:mb-3 md:order-3 relative">
					<div className="h-80">
						{isReady ? (
							<Fragment>
								{(playing || played > 0) && playingCurrentTrack ? (
									<BigPlayPause
										onClick={this.onClick}
										playing={shouldBePlaying}
									/>
								) : (
									<BigAudioPlayButton
										onClick={this.onClick}
										playing={shouldBePlaying}
									/>
								)}
							</Fragment>
						) : (
							<div className="w-screen">
								<Loader />
							</div>
						)}
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapState = state => ({
	...state.player
})

const mapDispatch = ({
	player: {
		setAudioBarVisibility,
		togglePlay,
		stop,
		setVolume,
		toggleMuted,
		onSeekChange,
		setSeekingState,
		reset,
		setTrack
	}
}) => ({
	setAudioBarVisibility,
	togglePlay,
	stop,
	setVolume,
	toggleMuted,
	onSeekChange,
	setSeekingState,
	reset,
	setTrack
})

export default connect(
	mapState,
	mapDispatch
)(AudioPlayer)
