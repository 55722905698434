import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import { pluckPrepItem } from '@/utils'

class Shows extends Component {
	constructor(props) {
		super(props)

		const {
			setAudioBarVisibility,
			showUi,
			setPageType,
			setCurrentPageId,
			entry,
			setTrack,
			playing,
			url
		} = props

		// pluckPrepItem just transforms the value from graphql into
		// something a little easier to work with, such as preparing
		// the image object
		const { id, title, subtitle, tags, image, body } = pluckPrepItem([entry])

		const { bannerImage } = entry

		const banner = bannerImage.length
			? { title: bannerImage[0].title, ...bannerImage[0].optimisedBanner }
			: false

		// set the page type... the pagetype prop is used to determine wether
		// the audio bar should be visible
		setPageType('audio')
		// set the current page id

		this.state = { title, subtitle, tags, image, id, body, url, banner }

		if (!showUi) {
			// set the current playlist and track
			// setPlayList({ playlist, index })
			// store the current ID

			setCurrentPageId(id)
			// if the audio bar is not visible... make it so

			// set the track
			setTrack(entry)
		} else {
			// if the component has been contructed and the audiobar is visible
			// that means we've come from another section of the site
			// and we should update the playlist about to be played
			// stored in queuedPlaylist prop
			// setCurrentPageId(id)

			// if we are not playing... the page has mounted, let's update the current track
			if (!playing) {
				// reset()
				setTrack(entry)
				setCurrentPageId(id)
			} else {
				setCurrentPageId(id)
			}
		}
	}

	render() {
		// we using a render prop here
		// the functionality is the same for the three audio
		// pages... but the html is different
		// so rather than duplicating code, we can use a render prop
		// when the component is used, one of the props passed should
		// be a function, called render... that function will retrive
		// an argument with all of the require state and props...
		// wooot
		const { render } = this.props

		return (
			<Fragment>
				{render({
					...this.state
				})}
			</Fragment>
		)
	}
}

// the code that follows connects the store to the component

// the store state gets mapped to the props
const mapState = state => ({
	...state.player
})

// the store reducers get mapped to the props
const mapDispatch = ({
	player: {
		setAudioBarVisibility,
		setPageType,
		setCurrentPageId,
		setTrack,
		stop,
		reset
	}
}) => ({
	setAudioBarVisibility,
	setPageType,
	setCurrentPageId,
	setTrack,
	stop,
	reset
})

// export the connected component
export default connect(
	mapState,
	mapDispatch
)(Shows)
