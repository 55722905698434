import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Image from '@/components/utils/Image'
import AudioPlayer from '@/components/AudioPlayer/AudioPlayer'
import Error from '@/components/Error/Error'
import errorIcon from '@/icons/ui-audio-error.svg'
// import { FadeBox } from '@/animation''

class Hero extends Component {
	render() {
		const { image, entry, hasError, track } = this.props

		return (
			<Fragment>
				<div className="relative overflow-hidden mb-1 lg:mb-3 pt-5">
					<div className="absolute pin-t pin-x w-full max-h-400 md:max-h-full h-full dimmer dimmer--image">
						<Image
							fluid={image}
							outerWrapperClassName="h-full"
							className="h-full"
						/>
					</div>
					<div className="flex flex-col justify-around items-center relative z-10">
						<AudioPlayer entry={entry} />
					</div>
					{hasError && track && (
						<div className="absolute z-30 bg-grey-brd pin h-full flex items-center justify-center w-full">
							<Error
								icon={errorIcon}
								title="uh oh! somethings gone wrong"
								message="please reload the page and try again"
							>
								<p className="text-xxs-fluid mt-1 text-grey-light">
									If that fails, try clicking{' '}
									<a
										target="_blank"
										rel="noopener noreferrer"
										href={track.mediaSource}
										className="text-error hover:text-teal"
									>
										here
									</a>
								</p>
							</Error>
						</div>
					)}
				</div>
			</Fragment>
		)
	}
}

const mapState = state => ({
	...state.player
})

const mapDispatch = ({ player: { setAudioBarVisibility, setError } }) => ({
	setAudioBarVisibility,
	setError
})

export default connect(
	mapState,
	mapDispatch
)(Hero)
