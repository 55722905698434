import React, { Fragment } from 'react'
import Slide from '@/components/Slide/Slide'
import SectionHeading from '@/components/SectionHeading/SectionHeading'
import PostThumb from '@/components/PostThumb/PostThumb'
import OrgThumb from '@/components/OrgThumb/OrgThumb'

export default ({ related, directory }) => (
	<Fragment>
		<div
			className={`${
				directory && directory.length > 0
					? 'border-b border-grey-brd pb-2 mb-2'
					: 'mb-2'
			}`}
		>
			<div className="wrapper">
				<div className="lg:flex lg:flex-wrap">
					<div className="lg:w-5/24 lg:mr-1/24">
						<SectionHeading title="Related" />
					</div>
					<div className="w-18/24 lg:w-18/24 relative">
						<Slide
							content={related}
							slidesToShow={3}
							wrapperClassName="lg:pr-3"
							render={item => <PostThumb {...item} />}
						/>
					</div>
				</div>
			</div>
		</div>
		{directory &&
			directory.length > 0 && (
			<div className="pb-2 mb-2">
				<div className="wrapper">
					<div className="lg:flex lg:flex-wrap">
						<div className="lg:w-5/24 lg:mr-1/24">
							<SectionHeading title="Directory" />
						</div>
						<div className="w-18/24 lg:w-18/24 relative">
							<Slide
								content={directory}
								slidesToShow={3}
								wrapperClassName="lg:pr-3"
								render={item => <OrgThumb {...item} />}
							/>
						</div>
					</div>
				</div>
			</div>
		)}
	</Fragment>
)
